<template>
    <MasterLayout :main_name="main_name" :link="link">
        <div class="sidebar_right_content booking_content">
            <div id="Mechanics" class="w3-container w3-border city">
                <ul class="ui-formSlide">
                    <!-- dashboard first section -->
                    <li data-step="bk_1" class="active" v-if="bookingList.length == 0">
                        <div class="v_bk_cat">
                            <img :src="frame">
                            <div class="v_con_main">
                                You Have No Upcoming Booking
                            </div>
                            <div class="bk_btn">
                                <router-link to="/villa" class="site_bk_btn">
                                    <span>Book Villa</span>
                                </router-link>
                            </div>
                        </div>
                    </li>
                    <!-- end dashboard first section -->
                    <li data-step="bk_2" v-else v-for="booking in bookingList" :key="booking.id">
                        <div class="mbr_detail_col_inr">
                            <div class="mbr_detail_block">
                                <ul class="mbr_detail_ul">
                                    <li class="mbr_detail_li">
                                        <div class="mbr_detail_div">
                                            <div class="mbr_detail_name" v-if="booking.main_villa.type == 'EventBooking'">
                                                <span class="villa_img">
                                                    <a v-if="booking.event.event_image[0]"
                                                        :href="booking.event.event_image[0].image" data-lightbox="models"
                                                        data-title="STRUCTURE-1">
                                                        <img :src="booking.event.event_image[0].image">
                                                    </a>
                                                </span>
                                                <span class="light_font">{{ booking.event.name }}</span>
                                            </div>
                                            <div class="mbr_detail_name"
                                                v-else-if="booking.main_villa.type == 'OneDayPicnic'">
                                                <span class="villa_img">
                                                    <a href="../../../public/images/inner_page/about_us_01.jpg"
                                                        data-lightbox="models" data-title="STRUCTURE-1">
                                                        <img src="../../../public/images/inner_page/about_us_01.jpg">
                                                    </a>
                                                </span>
                                                <span class="light_font">One Day Booking</span>
                                            </div>
                                            <div class="mbr_detail_name" v-else-if="booking.villa_type_master">
                                                <span class="villa_img">
                                                    <a :href="booking.villa_type_master.villa_images[0]"
                                                        data-lightbox="models" data-title="STRUCTURE-1">
                                                        <img :src="booking.villa_type_master.villa_images[0]">
                                                    </a>
                                                </span>
                                                <span class="light_font">{{ booking.villa_type_master.villa_name }}</span>
                                            </div>
                                        </div>
                                    </li>
                                    <li class="mbr_detail_li name_li">
                                        <div class="mbr_detail_div">
                                            <div class="mbr_detail_name">
                                                <span class="light_font">{{ booking.first_name }} {{ booking.middle_name }}
                                                    {{ booking.last_name }} <b class="bold_font">{{ booking.mobile
                                                    }}</b></span>
                                            </div>
                                        </div>
                                    </li>
                                    <li class="mbr_detail_li">
                                        <div class="mbr_detail_div">
                                            <div class="mbr_detail_name light_font">
                                                <span>{{ booking.villa_assign.check_in_date }} <span
                                                        class="to_grey">to</span> {{ booking.villa_assign.check_out_date
                                                        }}</span>
                                            </div>
                                        </div>
                                    </li>
                                    <li class="mbr_detail_li width_40">
                                        <div class="mbr_detail_div" style="justify-content: center;">
                                            <div class="mbr_detail_name light_font" v-if="booking.order.status == '0'">
                                                <span class="btn btn-sm btn-info text-white" disabled>No process</span>
                                            </div>
                                            <div class="mbr_detail_name light_font"
                                                v-else-if="booking.order.status == 'COMPLETED'">
                                                <span class="btn btn-sm btn-success text-white" disabled>Paid</span>
                                            </div>
                                            <div class="mbr_detail_name light_font" v-else-if="booking.order.status == '2'">
                                                <a :href="booking.order.pay_link_web"
                                                    class="btn btn-sm btn-warning text-white"
                                                    v-if="booking.order && booking.order.status === '2'">Pay Now</a>
                                                <!-- <span class="badge badge-warning">Pending</span> -->
                                            </div>
                                            <div class="mbr_detail_name light_font"
                                                v-else-if="booking.order.status == '3' || booking.order.status == '4'">
                                                <span class="btn btn-sm btn-danger text-white" disabled
                                                    v-if="booking.order.status == '3'">Cancelled</span>
                                                <span class="btn btn-sm btn-danger text-white" disabled
                                                    v-if="booking.order.status == '4'">Payment Failed</span>
                                            </div>
                                        </div>
                                    </li>
                                    <!-- <li class="mbr_detail_li width_40">
                                        <div class="mbr_detail_div qr_div">
                                            <div class="mbr_detail_name">
                                                <a :href="'http://chart.apis.google.com/chart?cht=qr&chs=250x250&chld=H|0&chl=' + booking.user_id + '-' + booking.mobile + '-' + booking.user.user_type + '&choe=UTF-8'"
                                                    data-lightbox="models" data-title="STRUCTURE-2" class="qr_href">
                                                    <img :src="'http://chart.apis.google.com/chart?cht=qr&chs=50x50&chld=H|0&chl=' + booking.user_id + '-' + booking.mobile + '-' + booking.user.user_type + '&choe=UTF-8'"
                                                        alt="QrCode">
                                                </a>
                                            </div>
                                        </div>
                                    </li> -->
                                </ul>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </MasterLayout>
</template>

<script>
import MasterLayout from './MasterLayout.vue';
import frame from '../../assets/images/User/Frame.png';
import axios from "axios";

export default {
    name: 'BookingHistory',
    components: { MasterLayout },
    data() {
        return {
            main_name: 'Booking History',
            link: [
                { 'link': 'home', 'name': 'Home' },
            ],
            frame: frame,
            token: localStorage.getItem('auth_cus_token'),
            bookingList: []
        }
    },
    mounted() {
        if (!this.token) {
            this.$router.push({ name: 'home' });
            window.location.href = "/";
        }
        this.getBookingHistory();

        window.scrollTo({ top: 0, behavior: "smooth" });
    },
    methods: {
        async getBookingHistory() {
            this.loader = true;
            await axios.get(process.env.VUE_APP_API_CUS_URL + "/booking-history",
                {
                    headers: {
                        "Content-Type": "application/json",
                        'Accept': "application/json",
                        "Authorization": `Bearer ${this.token}`
                    }
                })
                .then((response) => {
                    this.bookingList = response.data.data;
                    console.log('history: ', this.bookingList);
                }).catch((e) => {
                    if (e.response.status == 401) {
                        localStorage.removeItem('auth_otp');
                        localStorage.removeItem('auth_user_type');
                        localStorage.removeItem('auth_cus_token');

                        this.removeLocalstorage();

                        this.$router.push({ name: 'home' });
                        window.location.href = "/";
                    }
                });
        },
        removeLocalstorage() {
            localStorage.removeItem('id');
            localStorage.removeItem('check_in_date');
            localStorage.removeItem('check_out_date');
            localStorage.removeItem('total_child');
            localStorage.removeItem('total_adult');
            localStorage.removeItem('qty');

            localStorage.removeItem('total');
            localStorage.removeItem('festival_charge');
            localStorage.removeItem('extra_person_charge');
            localStorage.removeItem('gst_tax');
            localStorage.removeItem('gst_tax_percent');
        }
    }
}
</script>

<style scoped>
.mbr_detail_col_inr {
    background: #fff;
    margin-bottom: 10px;
    border-radius: 9px;
}

.mbr_detail_col_inr:hover {
    box-shadow: 0px 4px 4px #80808085;
    border-radius: 9px;
    cursor: pointer;
}

.villa_img {
    height: 50px;
}

.villa_img img {
    width: 50px;
    height: 50px;
    border-radius: 9px;
    margin-right: 5px;
}

.mbr_detail_name,
.mbr_detail_ul,
.qr_href {
    align-items: center;
    display: flex;
}

.mbr_detail_block {
    padding: 10px;
}

.to_grey {
    color: grey;
}

.bold_font {
    font-weight: 600;
}

.light_font {
    font-weight: 300;
}

.mbr_detail_li {
    margin-right: 10px;
}

.qr_div {
    justify-content: end;
}

.name_li {
    width: 120%;
}

.badge {
    padding: 5px;
    border-radius: 10px;
    color: #fff;
    width: 70px;
}

.badge-info {
    background-color: rgb(100, 198, 237);
}

.badge-success {
    background-color: rgb(100, 237, 132);
}

.badge-warning {
    background-color: rgb(237, 189, 100);
}

.badge-danger {
    background-color: rgb(237, 100, 100);
}

.width_40 {
    width: 40%;
}

.text-white {
    color: #fff !important;
}
</style>